import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getMainMasterReport=({
    startDate,
    endDate,
    type
})=>async dispatch=>{
    dispatch({
        type:actionTypes.MAIN_MASTER_REPORT_START,
    })
    await axios.get(`/reports/subsmasters/masters?startDate=${startDate}&endDate=${endDate}&type=${type}`)
            .then(response=>dispatch({
                type:actionTypes.MAIN_MASTER_REPORT_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.MAIN_MASTER_REPORT_FAIL,
                payload:getMessage(error)
            }))
}