import React, { Component } from 'react';
import { Col, Row, message, Button } from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { get4DReport } from "../../redux/actions/report/get4DReport";
import Pagination from "./Pagination/Pagination";
import './FourDReport.css';
import generatePDF from './Service/generatePDF';

class FourDReport extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  hover: false,
                  paginationXKey: 1,
                  paginationYKey: 1
            }
      }

      async componentDidMount() {
            await this.props.onGet();
            if (this.props.error) {
                  message.error(this.props.error);
            }
      }

      paginate = (array, page_size, page_number) => {
            return array.slice((page_number - 1) * page_size, page_number * page_size);
      }

      render() {
            const xPaginatedArray = this.paginate(this.props.fourDs ?? [],
                  1000, this.state.paginationXKey);
            const yPaginatedArray = this.paginate(xPaginatedArray ?? [],
                  100, this.state.paginationYKey);
            return (
                  <>
                        <Row>
                              <Col span={24}>
                                    <div style={styles.header} className="header">
                                          {/* this is for showing Pagination X. */}
                                          <div style={styles.paginationX}>
                                                <Pagination
                                                      numbers={10}
                                                      onChange={(key) => {
                                                            this.setState({
                                                                  paginationYKey: key
                                                            });
                                                      }}
                                                />
                                          </div>
                                          <Button
                                                onClick={() => generatePDF('4D', this.props.fourDs)}
                                                loading={this.props.loading}
                                          >
                                                Export as PDF
                                          </Button>
                                    </div>
                                    <div style={styles.body} className="body">
                                          <div style={styles.paginationY} className="paginationY">
                                                {/* this is for showing Pagination Y.*/}
                                                <Pagination
                                                      numbers={10}
                                                      onChange={(key) => {
                                                            this.setState({
                                                                  paginationXKey: key
                                                            });
                                                      }}
                                                />
                                          </div>
                                          <div style={{ width: '100%', overflow: 'auto' }}>
                                                <div className="numbers" style={styles.numbers}>
                                                      {/* this is for showing numbers*/}
                                                      {yPaginatedArray.map(obj =>
                                                            <div style={{
                                                                  ...styles.number,
                                                                  borderBottom: (obj.status == 1)
                                                                        ? '5px solid green' : obj.status == 2
                                                                              ? '5px solid red' : '5px solid gray',
                                                            }} className="number">
                                                                  <div style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'space-between'
                                                                  }}>
                                                                        <span>{obj.fourDNumber} </span>
                                                                  </div>
                                                                  <span style={{
                                                                        fontSize: '0.6rem',
                                                                        color: 'gray'
                                                                  }}>Amount</span>
                                                                  <span>{`${obj.totalBetAmount}`
                                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                                            </div>)}
                                                </div>
                                          </div>
                                    </div>
                              </Col>
                        </Row>
                  </>
            );
      }
}

const mapStateToProps = state => ({
      error: state.report.error,
      fourDs: state.report.fourDs,
      loading:state.report.loading,
})

const mapDispatchToProps = dispatch => ({
      onGet: () => dispatch(get4DReport())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FourDReport));

const styles = {
      header: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '60px',
      },
      body: {
            display: 'flex',
            paddingTop: '8px'
      },
      paginationX: {
            display: 'flex',
            overflowX: 'auto'
      },
      paginationY: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto'
      },
      numbers: {
            paddingLeft: '8px',
            display: 'grid',
            gridTemplateColumns: "repeat(10,100px)",
            gridTemplateRows: "repeat(10,100px)",
            gridGap: '5px',
            justifyContent: 'start',
            overflow: 'auto'
      },
      number: {
            display: 'flex',
            flexDirection: 'column',
            padding: 5,
            border: '1px solid gray',
            justifyContent: 'center',
            color: '#0e0e0e'
      }
}