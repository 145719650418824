import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getMasterReport=({
    date,
    smasterName,
    type
})=>async dispatch=>{
    dispatch({
        type:actionTypes.MASTER_REPORT_START,
    })
    await axios.get(`/reports/subsmasters/daily/masters?type=${type}&date=${date}`)
            .then(response=>dispatch({
                type:actionTypes.MASTER_REPORT_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.MASTER_REPORT_FAIL,
                payload:getMessage(error)
            }))
}