import update from '../utilities/update';
import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_LOGOUT,
    CHANGE_PASSWORD_START,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,
} from '../actions/auth/actionTypes';
import {getMessage} from '../utilities/getMessage';
import {loading} from './shared/loading';
import {error} from './shared/error';

const initialState = {
    token: null,
    error: null,
    loading: false,
};

const authStart = (state, action) => {
    return update(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
    return update(state, {
        token: action.token,
        error: null,
        loading: false,
    });
};

const authFail = (state, action) => {
    return update(state, { error: getMessage(action.payload), loading: false });
};

const authLogout = (state, action) => {
    return update(state, { token: null });
};

const changePassword=(state,action)=>{
    return update(state,{
        loading:false,
        message:'changed password successfully'
    })
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_START:
            return authStart(state, action);
        case AUTH_SUCCESS:
            return authSuccess(state, action);
        case AUTH_FAIL:
            return authFail(state, action);
        case AUTH_LOGOUT:
            return authLogout(state, action);
        case CHANGE_PASSWORD_START:
            return loading(state,action);
        case CHANGE_PASSWORD_SUCCESS:
            return changePassword(state,action);
        case CHANGE_PASSWORD_FAIL:
            return error(state,action);
        default:
            return state;
    }
};

export default authReducer;