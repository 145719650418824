import { Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getThreeDReport } from '../../redux/actions/report/getThreeDReport';
import Pagination from './Pagination/Pagination';
import generatePDF from './Service/generatePDF';

export default function ThreeDReport() {
      const [paginationKey, setPaginationKey] = useState(1);
      const dispatch = useDispatch();
      const { threeDs, error, threeDAverage, threeDtotal, threeDmax, threeDmaxNumber, loading } = useSelector(state => state.report);
      useEffect(async () => {
            await dispatch(getThreeDReport());
            if (error) {
                  message.error(error);
            }
      }, []);
      const paginate = (array, page_size, page_number) => {
            return array?.slice((page_number - 1) * page_size, page_number * page_size);
      }
      return (
            <>
                  <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        margin: '0 20px 10px 20px'
                  }}>
                        <div>
                              Total Amount:{parseInt(threeDtotal)}
                        </div>
                        <div>
                              Max:{parseInt(threeDmax)}$ | <span style={{ color: 'red' }}>{parseInt(threeDmaxNumber)}</span>
                        </div>
                        <div>
                              Average:{parseInt(threeDAverage)}
                        </div>
                        <div style={{
                              display: 'flex',
                        }}>
                              <Pagination
                                    numbers={10}
                                    onChange={(key) => {
                                          setPaginationKey(key);
                                    }}
                              />
                        </div>
                        <Button
                              onClick={() => generatePDF('3D', threeDs)}
                              loading={loading}
                        >
                              Export as PDF
                        </Button>
                  </div>
                  <div style={{ width: '100%', overflow: 'auto' }}>
                        <div style={{
                              display: 'grid',
                              gridTemplateColumns: "repeat(10,100px)",
                              gridTemplateRows: "repeat(10,100px)",
                              gridGap: '5px',
                              justifyContent: 'start',
                              overflow: 'scroll'
                        }}>
                              {
                                    paginate(threeDs, 100, paginationKey)?.map(obj => <div style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          padding: 5,
                                          border: '1px solid gray',
                                          borderBottom: (obj.status == 1) ? '5px solid green' : obj.status == 2 ? '5px solid orange' : obj.status == 3 ? '5px solid red' : '1px solid gray',
                                          justifyContent: 'center',
                                          color: '#0e0e0e'
                                    }}
                                    >
                                          <span>
                                                {obj.threeDNumber}
                                          </span>
                                          <span style={{
                                                fontSize: '0.6rem',
                                                color: 'gray'
                                          }}>
                                                Amount
                                          </span>
                                          <span>
                                                {`${obj.totalBetAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                          </span>
                                    </div>)
                              }
                        </div>
                  </div>

            </>
      );
}