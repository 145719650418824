import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getCurrentUser=()=>async dispatch=>{
    dispatch({
        type:actionTypes.CURRENT_USER_START
    })
    await axios.get('/user/me')
            .then(response=>dispatch({
                type:actionTypes.CURRENT_USER_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.CURRENT_USER_FAIL,
                payload:getMessage(error)
            }))
}