import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getMemberReport=({
    date,
    agentName,
    type
})=>async dispatch=>{
    dispatch({
        type:actionTypes.MEMBER_BY_COLLECTION_AND_AGENT,
    })
    await axios.get(`/reports/daily/users?date=${date}&agentName=${agentName}&type=${type}`)
            .then(response=> dispatch({
                type:actionTypes.MEMBER_BY_COLLECTION_AND_AGENT_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.MEMBER_BY_COLLECTION_AND_AGENT_FAIL,
                payload:getMessage(error)
            }))
}