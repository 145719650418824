import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMasters } from '../../redux/actions/master/getMasters';
import {
    TeamOutlined,
    FundViewOutlined
} from '@ant-design/icons';
import { getCurrentUser } from '../../redux/actions/user/getCurrentUser';

export default function () {

    const dispatch = useDispatch();
    const masters = useSelector(state => state.master.all);
    const user = useSelector(state => state.user.current);
    
    useEffect(() => {
        dispatch(getMasters());
        dispatch(getCurrentUser())
    }, []);

    return (
        <>
            <div style={styles.boxWrapper}>
                <div style={styles.box}>
                    <div style={{
                        ...styles.icon,
                        ...styles.masterIcon
                    }}>
                        <TeamOutlined />
                    </div>
                    <div style={{
                        ...styles.master,
                        ...styles.numWrapper
                    }}>
                        <span style={styles.number}>{masters.length}</span>
                        <span>Masters</span>
                    </div>
                </div>
                <div style={styles.box}>
                    <div style={{
                        ...styles.icon,
                        ...styles.downlineIcon
                    }}>
                        <FundViewOutlined />
                    </div>
                    <div style={{
                        ...styles.downline,
                        ...styles.numWrapper
                    }}>
                        <span style={styles.number}>{user?.downLineBalance}</span>
                        <span>DownLine Balance</span>
                    </div>
                </div>
            </div>

        </>
    );
}

const styles = {
    boxWrapper: {
        display: 'flex'
    },
    box: {
        display: 'flex',
        width: 320,
        height: 80,
        boxSizing: 'border-box',
        boxShadow: '1px 1px 5px 0px gray',
        marginRight: '10px',
        borderRadius: '5px',
        overflow: 'hidden'
    },
    icon: {
        width: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '3rem'
    },
    downlineIcon: {
        color: '#FF6B6B'
    },
    downline: {
        backgroundColor: '#FF6B6B'
    },
    numWrapper: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        color: 'white',
        padding: '10px'
    },
    masterIcon: {
        color: '#3f37c9',
    },
    master: {
        backgroundColor: '#3f37c9',
    },
    number: {
        fontSize: '1.3rem',
    },
    balanceIcon: {
        color: '#55a630'
    },
    balance: {
        backgroundColor: '#55a630'
    },
}