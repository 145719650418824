export const getSelectedKey = ({ pathname }) => {
      if (pathname.includes("/bet_detail/") && /\d/.test(pathname)) {
            return ['16'];
      }
      if (pathname.includes("/2d_detail/") && /\d/.test(pathname)) {
            return ['16'];
      }
      if (pathname.includes("/3d_detail/") && /\d/.test(pathname)) {
            return ['16'];
      }
      if (pathname.includes("/4d_detail/") && /\d/.test(pathname)) {
            return ['16'];
      }
      switch (pathname) {
            case "/":
                  return ['22'];
            case "/collection_report":
                  return ['16'];
            case "/master_report":
                  return ['18'];
            case "/account":
                  return ['20'];
            case "/2d_report":
                  return ['19'];
            case "/3d_report":
                  return ['21'];
            case "/4d_report":
                  return ['24'];
            default:
                  return ['2'];
      }
}