import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';
import {
    CURRENT_USER_START,
    CURRENT_USER_SUCCESS,
    CURRENT_USER_FAIL
} from '../actions/user/actionTypes';

const initialState={
    loading:false,
    error:null,
    message:null,
    current:null
}

const getCurrent=(state,action)=>{
    return update(state,{
        loading:false,
        current:action.payload
    })
}

const userReducer=(state=initialState,action)=>{
    switch(action.type)
    {
        case CURRENT_USER_START:
            return loading(state,action);
        case CURRENT_USER_SUCCESS:
            return getCurrent(state,action);
        case CURRENT_USER_FAIL:
            return error(state,action);
        default:
            return state;
    }
}

export default userReducer;