import { Route, Redirect } from 'react-router-dom';
import {useSelector} from 'react-redux';
function PrivateRoute({ children, ...rest }) {
    const isAuthenticated=useSelector(state=>state.auth.token!==null);
    return (
        <Route {...rest} render={({location}) => {
            return isAuthenticated === true
                ? children
                : <Redirect to={{
                    pathname: '/login',
                    state: { from: location?.pathname }
                }} />
        }} />
    )
}

export default PrivateRoute;