import { Layout, Button } from 'antd';
import React from 'react';
import brand from '../assets/brand.svg';
import { connect } from 'react-redux';
import { getCurrentUser } from '../redux/actions/user/getCurrentUser';
import DefaultMenu from './DefaultMenu';
import MobileMenu from './MobileMenu';
import {
      MenuUnfoldOutlined,
      MenuFoldOutlined
} from '@ant-design/icons';

const { Header, Content, Sider } = Layout;

class App extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  visible: false,
                  isMobile: false,
                  isTablet: false,
            }
            this.toggleMenu = this.toggleMenu.bind(this);
            this.headerRef = React.createRef();
      }

      async componentDidMount() {
            const mobileQuery = window.matchMedia(`(max-width: 480px)`);
            const tabletQuery = window.matchMedia(`(min-width: 481px) 
      and (max-width: 1024px)`);
            mobileQuery.onchange = (e) => {
                  this.setState(prevState => {
                        return {
                              ...prevState,
                              isMobile: e.matches
                        }
                  })
            }
            tabletQuery.onchange = (e) => {
                  this.setState(prevState => {
                        return {
                              ...prevState,
                              isTablet: e.matches
                        }
                  })
            }
            this.setState(prevState => {
                  return {
                        ...prevState,
                        isTablet: tabletQuery.matches,
                        isMobile: mobileQuery.matches,
                  }
            })
            await this.props.onGetCurrentUser();
      }
      toggleMenu() {
            this.setState({ visible: !this.state.visible })
      }
      render() {
            const { isMobile, isTablet } = this.state;
            const computedHeight = `calc(100vh - ${this.headerRef?.current?.offsetHeight ?? 0}px)`;
            return (
                  <Layout style={{ minHeight: '100vh' }}>
                        <Header>
                              <div
                                    ref={this.headerRef}
                                    style={{
                                          display: 'flex',
                                          justifyContent: 'flex-start',
                                          alignItems: 'center',
                                          color: '#B5BAC7',
                                          fontSize: '16px'
                                    }}>
                                    <img src={brand} alt="555Mix" style={{
                                          marginRight: '10px',
                                          width: 90
                                    }} />
                                    <span> Sub Smaster</span>
                                    {(isMobile && this.props.token) ? <Button style={styles.toggleButton}
                                          onClick={this.toggleMenu}>
                                          {this.state.visible ? <MenuUnfoldOutlined /> :
                                                <MenuFoldOutlined />}
                                    </Button> : null}
                              </div>
                        </Header>
                        <Layout>
                              {(isMobile && this.props.token) ? <MobileMenu
                                    toggleMenu={this.toggleMenu}
                                    visible={this.state.visible} /> : isTablet ?
                                    <Sider
                                          style={{
                                                boxSizing: 'border-box',
                                                overflow: 'auto',
                                                height: computedHeight
                                          }}
                                          key="tablet-sider"
                                          defaultCollapsed={true}
                                          collapsible>
                                          <DefaultMenu />
                                    </Sider> : <Sider
                                          style={{
                                                boxSizing: 'border-box',
                                                overflow: 'auto',
                                                height: computedHeight
                                          }}
                                          key="default-sider"
                                          collapsible>
                                          <DefaultMenu />
                                    </Sider>}
                              <Content style={{
                                    margin: '0',
                                    padding: 24,
                                    background: '#f7f7ff',
                                    overflow: 'auto',
                                    maxHeight: computedHeight,
                                    boxSizing: 'border-box'
                              }}>
                                    {this.props.children}
                              </Content>
                        </Layout>
                  </Layout>
            );
      }
}

const styles = {
      toggleButton: {
            margin: 0,
            marginLeft: 'auto',
            padding: 0,
            fontSize: '18px',
            backgroundColor: 'transparent',
            border: 0,
            outline: 'none',
            color: 'white'
      }
}

const mapStateToProps = state => ({
      token: state.auth.token,
})

const mapDispatchToProps = dispatch => ({
      onGetCurrentUser: () => dispatch(getCurrentUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(App);