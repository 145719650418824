import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getMasters=()=>async dispatch=>{
    dispatch({
        type:actionTypes.GET_MASTERS_START
    })
    await axios.get('/subsmasters/masters')
            .then(response=>dispatch({
                type:actionTypes.GET_MASTERS_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.GET_MASTERS_FAIL,
                payload:getMessage(error)
            }))
}