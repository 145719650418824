import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getAgentReport=({
    date,
    masterName,
    type
})=>async dispatch=>{
    dispatch({
        type:actionTypes.AGENT_BY_COLLECTION_AND_MASTER,
    })
    await axios.get(`/reports/daily/agents?masterName=${masterName}&type=${type}&date=${date}`)
            .then(response=> dispatch({
                type:actionTypes.AGENT_BY_COLLECTION_AND_MASTER_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.AGENT_BY_COLLECTION_AND_MASTER_FAIL,
                payload:getMessage(error)
            }))
}