import './mobile-menu.css';
import { Drawer } from 'antd';
import DefaultMenu from './DefaultMenu';

function MobileMenu({
   visible,
   toggleMenu
}) {
   return (
      <Drawer placement="left"
         closeIcon={null}
         onClose={toggleMenu}
         visible={visible}>
         <DefaultMenu theme="light" style={menu} />
      </Drawer>
   );
}

const menu = {
   width: '100%',
   height: '100%',
   padding: 0
}

export default MobileMenu;