import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const changePassword=(oldPassword,newPassword)=>async dispatch=>{
    dispatch({
        type:actionTypes.CHANGE_PASSWORD_START
    })
    const options={
        url:'/user/changePassword',
        method:'POST',
        data:{
            oldPassword,
            newPassword
        }
    }
    await axios(options)
                .then(response=>dispatch({
                    type:actionTypes.CHANGE_PASSWORD_SUCCESS,
                }))
                .catch(error=>dispatch({
                    type:actionTypes.CHANGE_PASSWORD_FAIL,
                    payload:getMessage(error)
                }))
}