import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
    GET_MASTERS_START,
    GET_MASTERS_SUCCESS,
    GET_MASTERS_FAIL,
} from '../actions/master/actionTypes';

const initialState = {
    loading: false,
    error: null,
    message: null,
    all: [],
}

const getMasters = (state, action) => {
    return update(state, {
        loading: false,
        all: action.payload
    })
}

const seniormasterReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MASTERS_START:
            return loading(state, action);
        case GET_MASTERS_SUCCESS:
            return getMasters(state, action);
        case GET_MASTERS_FAIL:
            return error(state, action);
        default:
            return state;
    }
}

export default seniormasterReducer;