import * as actionTypes from './actionTypes';
import axios from '../../utilities/interceptor';
import { getMessage } from '../../utilities/getMessage';

export const getBetDetail=(id)=>async dispatch=>{
    dispatch({
        type:actionTypes.GET_BET_DETAIL_START,
    })
    await axios.get(`/bets/${id}`)
            .then(response=>dispatch({
                type:actionTypes.GET_BET_DETAIL_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.GET_BET_DETAIL_FAIL,
                payload:getMessage(error)
            }))
}