import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';
import {
    GET_BET_DETAIL_START,
    GET_BET_DETAIL_SUCCESS,
    GET_BET_DETAIL_FAIL
} from '../actions/betPattern/actionTypes';

const initialState={
    loading:false,
    error:null,
    message:null,
    betDetail:null
}

const getBetDetail=(state,action)=>{
    return update(state,{
        loading:false,
        betDetail:action.payload
    })
}

const betPatternReducer=(state=initialState,action)=>{
    switch(action.type)
    {
        case GET_BET_DETAIL_START:
            return loading(state,action);
        case GET_BET_DETAIL_SUCCESS:
            return getBetDetail(state,action);
        case GET_BET_DETAIL_FAIL:
            return error(state,action);
        default:
            return state;
    }
}

export default betPatternReducer;